@import "../config";
@import "../../../bower_components/bourbon/app/assets/stylesheets/bourbon";

.header{
  position: fixed !important;
  font-family: $fontRoboto;
  font-size: 14px;
  z-index: 5000;
  top: 0;
  left: 0;
  width: 100%;
  height: $headerHeight;
  padding-top: 31px;
  background-color: white;
  border-bottom: 1px solid $colorOffWhite;
  transition: box-shadow 0.2s;

  &--overflow {
    .header-seperator {
      display: none;
    }

    &-logo img {
      height: 32px;
      margin-top: 10px;
      margin-right: 20px;
    }
  }

  &-overlay {
    position: absolute;
    z-index: 5;
    top: 100%;
    left: 0;
    width: 100%;
    &-content{
      background-color: white;
      max-height: 0;
      overflow: hidden;
      @include transition(max-height .2s);
      box-shadow: 0 3px 7px 0 rgba(black, .04), inset 0 1px 0 0 $colorOffWhite;
      .productmenu{
        margin-bottom: 0!important;
        &-item{
          padding-top: 44px;
          padding-bottom: 44px;
        }
      }
    }
    &--search{
      z-index: 3;
    }
  }

  &-logo {
    float: left;
    max-width: 216px;
    margin-top: -17px;
    height: 36px;
    line-height: 12px;
    @include vertical-center-child(12px);
    img {
      max-width: 100%;
    }
  }
  &-seperator{
    float: left;
    background-color: $colorBlueLight;
    width: 1px;
    height: 21px;
    margin: 0px 28px;
  }
  &-mobile-logo {
    margin-left: 10px;
    display: none;
    margin-top: 1px;
  }
  &-tools {
    position: relative;
    @include clearfix();
    display: inline-block;
    &-item{
      display: inline-block;
      margin-right: 20px;
      padding-right: 18px;
      float: left;
      text-decoration: none;
      color: $colorGreyDark!important;
      border-right: 1px solid $colorTheme;
      white-space: nowrap;

      &--borderless {
        border: none;
        padding-right: 0;
      }

      &:hover{
        //color: lighten($colorGreyDark, 10)!important;
        @include hovereffect();
      }

      &-image {
        display: inline-block;
        height: 24px;
        width: 24px;
        vertical-align: middle;
        margin-top: -3px;
        margin-left: 8px;
        background-size: cover;
      }

      &:last-child{
        padding-right: 0;
        margin-right: 0;
        border-right: none;
      }
      &--search, &--language{
        padding-right: 0;
        margin-top: 1px;
        @include hovereffect();
        border-right: none;
        img{
          &:nth-child(2){
            display: none;
          }
        }
      }
      &--active{
        &.header-tools-item--search, &.header-tools-item--language{
          img{
            &:nth-child(1){
              display: none;
            }
            &:nth-child(2){
              display: block;
            }
          }
        }
      }
    }
    &-wrapper{
      float: left;
    }
  }
  &-burger {
    @include hovereffect();
    width: 35px;
    height: 35px;
    margin-top: -9px;
    float: right;
    display: none;
    background: url('/img/burger.png') no-repeat center center;
    background-size: 21px auto;
    &--open {
      background-image: url('/img/mobilemenu-burger-close.png');
      background-size: 18px auto;
    }
    &--dark{
      background: url('/img/burger_dark.png') no-repeat center center;
      background-size: 21px auto;
      &.header-burger--open{
        background-image: url('/img/mobilemenu-burger-close-dark.png');
        background-size: 18px auto;
      }
    }
  }
  &--plus{
    .menu{
      &-item{
        > a{
          height: auto;
        }
        &:not(:first-child) {
          margin-left: 19px;
        }
      }
    }
  }
  &--simple {
    padding-top: 18px;
    img {
      display: inline-block;
    }
  }
  &--hidden{
    box-shadow: none!important;
  }
  @at-root .body--gap &{
    box-shadow: 0 3px 7px 0 rgba(black, .04);
  }
  &--scrolled{
    box-shadow: 0 3px 7px 0 rgba(black, .04);
  }
  .header-overlay--active {
    .header-overlay-content{
      max-height: $overlayheight;
      @include transition(max-height, .5s);
    }
  }


  @media screen and (max-width: $burgerWidth) {
    top: 0!important;
    padding-top: 25px;
    padding-bottom: 0;
    height: $headerHeightMobile;
    &-burger {
      display: block;
    }
    .menu:not(.menu--persistent){
      display: none;
    }
    &-seperator{
      display: none;
    }
    &-logo {
      display: none;
    }
    &-tools:not(&-tools--persistent) {
      display: none;
    }
    &-mobile-logo {
      display: block;
      max-height: 32px;
      margin-top: -9px;
    }
    .login{
      margin-right: 10px;
    }
    &--simple {
      padding-top: 12px;
    }
  }
  &[max-width~="850px"] {
    .header-tools {
      &-item{
        padding-right: 0;
        border: none;
      }
    }
  }
  @include media($desktop) {
    &-logo {
      max-width: 180px;
      margin-top: -16px;
    }
    &-tools-find-distributor {
      padding-right: 7px;
      margin-right: 7px;
    }
    &-tools-item{
      padding-right: 0;
      margin-right: 8px;
      border: none;
    }
    &-seperator{
      margin: 0px 12px;
    }
    .menu{
      &-item{
        &:not(:first-child) {
          margin-left: 8px;
        }
      }
    }
  }
  @include media($mobile) {
    &-tools--persistent {
      margin-top: -3px;
    }
    padding-bottom: 0;
    height: $headerHeightMobile;
    &-mobile-logo{
      max-width: calc(100% - 15px);
    }
  }
}
