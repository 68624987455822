@mixin vertical-center-child($size) {
  // https://css-tricks.com/centering-in-the-unknown/

  font-size: 0;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  & > * {
    display: inline-block;
    vertical-align: middle;
    font-size: $size;
  }
}
