@import "../../bower_components/normalize-css/normalize.css";

@import "../../bower_components/bourbon/app/assets/stylesheets/bourbon";
@import "../../bower_components/neat/app/assets/stylesheets/neat-helpers";
@import "config";
@import "../../bower_components/neat/app/assets/stylesheets/neat";
@import "../../bower_components/kickstart-style/grid";
@import "../../bower_components/kickstart-style/mixins/vertical-center-child";
@import "mixins";
@import "extends";
//@import "base";
@import "helpers";

//@import "components/body";

body {
  font-family: $fontRoboto;
  font-size: 12px;
  line-height: 1.5;
}

@import "components/menu";
@import "components/topic";
@import "components/mobilemenu";
@import "components/header";
@import "components/themetext";
@import "components/button";
@import "components/subtitle";
@import "components/text";
@import "components/login";

.btn-primary, .nav-pills > li.active > a, .nav-pills > li > a.active {
  background-color: $colorTheme !important;
}

.topic-list-item:nth-child(even) {
  background-color: lighten($colorTheme, 40);
}

#current-user {
  display: none;

  .staff & {
    display: inline-block;
  }

}

.wrap {
  max-width: 1140px;
}

body {
  font-family: $fontRoboto;
  //padding-top: 30px;

  @include media($tablet) {
    //padding-top: 0;
    margin-top: 20px !important;
  }

  @include media($mobile) {
    //padding-top: 0;
  }
}

#main-outlet {
  padding-top: 90px !important;

  @include media($tablet) {
    padding-top: 65px !important;
  }

  @include media($mobile) {
    padding-top: 50px !important;
  }
}

@media screen and (max-width: $burgerWidth) {
  .d-header {
    position: fixed !important;
    top: 0 !important;
  }
}

.header--plus .header-logo {
  margin-top: -12px;
}

header.d-header {
  pointer-events: none;

  #site-text-logo {
    display: none;
  }

  padding-top: 14px;
  height: $headerHeight;
  background: transparent;

  @media screen and (max-width: $burgerWidth) {
    padding-top: 10px;
    height: $headerHeightMobile;
  }

  .wrap {
    padding: 0;
    max-width: 1140px;
  }

  .d-header-icons {
    pointer-events: auto;
    margin-right: 60px;
    margin-top: 4px;
    li {
      margin-left: 5px;
    }

    @include media($desktop) {
      margin-right: 80px;
    }

    @media screen and (max-width: $burgerWidth) {
      margin-top: 2px;
      margin-right: 110px;
    }
  }

  @include media($mobile) {
    .title {
      height: auto !important;
    }
  }

  @media screen and (max-width: $burgerWidth) {
    .contents .title, .contents .extra-info-wrapper {
      display: none !important;
    }
  }

  .contents .title, .contents .extra-info-wrapper {
    margin-top: -7px;
    pointer-events: auto;
    background: white;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    visibility: hidden;
  }

  .contents .extra-info-wrapper {
    @extend .grid-span3;
    @extend .grid-tablet-span6;
  }

  box-shadow: none;
}

header.header {
  position: fixed !important;
  top: 0 !important;
  //padding-top: 0 !important;
  //overflow-y: hidden;
  margin-bottom: 0;
  //border-bottom: 0;
  z-index: 999; // discourse has 1000
}

li.header {
  position: relative !important;
  height: auto !important;
  padding-bottom: 0;
}

.mobilemenu {
  top: $headerHeightMobile !important;
}

@include media($mobile) {
  body .header.header--plus .header-mobile-logo {
    margin-left: 0;
  }
  #current-user {
    display: none !important;
  }
  .header-dropdown-toggle:nth-child(2) {
    display: none;
  }
}

.menu-panel {
  pointer-events: auto;
}

/*############
Grid code
############*/
@include grid(10, (desktop: $desktop, tablet: $tablet, abovetablet: $abovetablet, mobile: $mobile));

.grid-outer-container {
  @include media($desktop) {
    @include outer-container(calc(100% - 40px));
  }
  @include media($tablet) {
    @include outer-container(calc(100% - (#{$mobileSideGap} * 2)));
  }
}

/*
 * -------- *
 */

.quote {
  &--accepted {
    background: transparent;
    color: $colorGreyDark;

    > *, .title {
      border-color: $colorBlueLight !important;
      background-color: rgba($colorBlueLight, 0.15) !important;
      color: $colorGreyDark !important;
    }

    .title {
      padding-top: 15px;
    }

    .fa-check-square:before {
      color: $colorGreen;
      margin-right: 5px;
    }

    blockquote, .blockquote {
      margin-bottom: 0;
      width: 100% !important;

      &.reference {
        border-left: 5px solid $colorBlueLight;
        padding: 0 12px 15px;
      }
    }
  }
}

nav.post-controls {
  .actions {
    > * {
      vertical-align: top;
      display: inline-block !important;
    }

    > *:not(:first-child) {
      margin: 0 0 0 10px;

      @include media($tablet) {
        margin: 0 0 0 5px;
      }

      @include media($mobile) {
        margin: 0 0 0 3.5px;
      }
    }

    > *:first-child {
      margin: 0;
    }
  }

  $buttonSize: 32px;
  .widget-button, .like-button {
    height: $buttonSize;
    max-height: $buttonSize;
    background: transparent;
    border-radius: $buttonSize / 2;
    font-size: 14px !important;

    &:not(.reply) {
      width: $buttonSize;
      max-width: $buttonSize;
    }

    .d-button-label {
      margin-left: 10px;
    }
  }

  .accepted, .unaccepted {
    background-color: $colorGreen !important;
    color: white;
    transition: background-color 0.1s;
    max-width: none !important;
    width: auto !important;
    font-weight: normal !important;
    padding: 0 15px !important;
    opacity: 1 !important;

    &:hover {
      background-color: darken($colorGreen, 5) !important;
      color: white;
    }

    .d-button-label {
      font-weight: 300 !important;
      letter-spacing: 0 !important;
    }
  }

  .unaccepted {
    position: relative;
    width: $buttonSize !important;
    max-width: $buttonSize !important;
    transition: all 0.2s;
    overflow: hidden;
    padding: 0 10px !important;

    &:after {
      position: absolute;
      left: 30px;
      content: 'Mark as solution';
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-left: 5px;
      font-weight: 300 !important;
      letter-spacing: 0 !important;
      opacity: 0;
      color: white !important;
    }

    &:hover {
      text-align: left;
      width: 150px !important;
      max-width: 150px !important;
      padding: 0 15px !important;

      &:after {
        opacity: 1;
      }
    }

    @include media($tablet) {
      &:after {
        display: none !important;
      }

      &:hover {
        text-align: center;
        max-width: $buttonSize !important;
        width: $buttonSize !important;
        padding: 0 10px !important;
      }
    }
  }

  .accepted-text {
    display: none !important;
  }

  .like-button {
    position: relative;
    background: $colorLightRed;
    transition: background-color 0.1s;

    &:empty {
      display: none !important;
    }

    .like-count {
      position: absolute;
      top: -6px !important;
      right: -5px !important;
      background: #fff !important;
      border: 1px solid $colorLightRed;
      z-index: 3;
      width: 17px;
      height: 17px;
      font-size: 10px !important;
      text-align: center;
      padding: 0 !important;
      color: $colorLightRed !important;
      margin: 0 !important;

      &.my-likes {
        i {
          padding: 0 !important;
          color: white !important;
          opacity: 1 !important;

          &:before {
            position: absolute;
            top: 5px;
            left: -9.5px * 2 - 2px;
            font-size: 14px !important;
            padding: 10px 9.5px !important;
            border-radius: 50%;
          }
        }
      }
    }

    .toggle-like {
      padding: 0 !important;
      text-align: center;
      color: white !important;
      opacity: 1 !important;
      margin: 0 !important;

      i {
        margin-left: 1px;
        margin-top: 1px;
      }
    }

    &:hover {
      background: darken($colorLightRed, 5) !important;

      .toggle-like {
        @include hovereffect();
      }
    }
  }
}

.accepted-solution {
  position: relative;

  .topic-avatar {
    position: relative;

    &:after {
      content: '\f00c';
      font-family: 'FontAwesome';
      position: absolute;
      top: calc(100% + 15px);
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 24px;
      color: $colorBlueLight;
    }
  }

  .topic-body {
    position: relative;

    .regular.contents {
      &:before {
        $extraWidth: 10px;
        pointer-events: none;
        background-color: rgba($colorBlueLight, 0.08);
        position: absolute;
        left: -45px - $extraWidth;
        top: -49px;
        width: calc(100% + 45px + 2 * #{$extraWidth});
        height: calc(100% + 50px + 20px);
        content: ' ';
        border-top: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
      }
    }
  }
}

.mobile-device {
  .accepted-solution {
    .topic-avatar {
      &:after {
        display: none;
      }
    }
  }

  .like-button {
    .like-count {
      &.my-likes {
        padding: 0 0 0 4px !important;

        i:before {
          margin-top: -1px;
        }
      }
    }
  }

  .topic-body {
    .regular.contents {
      &:before {
        display: none !important;
      }
    }
  }
}