.mobilemenu {
  @include transition(left .3s, width 0s 0.3s);
  position: fixed;
  top: $headerHeightMobile;
  bottom: 0;
  color: white;
  left: 100%;
  width: 0;
  max-height: 100%;
  overflow-y: auto;
  background: $colorGreyDark;
  z-index: 9999;

  &--shown {
    @include transition(left .3s);
    left: 0;
    width: 100%;
  }

  hr {
    margin: 25px;
    background-color: white;
  }

  &-search {
    $inputHeight: 60px;

    position: relative;
    background-color: $colorGreyDarker;

    .formitem {
      margin-bottom: 0 !important;
    }

    input {
      padding-left: 25px;
      font-family: $fontRobotoCondenced;
      font-size: 20px;
      font-weight: 100;
      height: $inputHeight;
      color: white;
      text-align: left;
      border-radius: 0;
      margin-bottom: 0 !important;

      &::placeholder {
        color: white;
      }
    }

    &-button {
      $padding: 7px;
      border: none;
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      display: inline-block;
      height: $inputHeight;
      width: $inputHeight;
      content: '';
      background: url('/img/mobilemenu-search.png') no-repeat center center;
      background-size: 20px auto;

      &--close {
        background: url('/img/mobilemenu-search-close.png') no-repeat center center;
        background-size: 18px auto;
      }
    }
  }

  &-links {
    $linkHeight: 50px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    white-space: normal;

    &-pagelink, &-menulink, &-backlink {
      position: relative;
      display: inline-block;
      width: 100%;
      height: $linkHeight;
      line-height: $linkHeight;
      font-family: $fontRobotoCondenced;
      font-weight: 400;
      font-size: 25px;
      padding-left: 25px;
      color: white;
      text-transform: uppercase;
      text-decoration: none;

      &:first-child {
        margin-top: 15px;
      }

      &:last-child {
        margin-bottom: 15px;
      }
    }

    &-backlink {
      color: $colorBlueLight;
      padding-left: 50px;

      &::before {
        position: absolute;
        left: 25px;
        top: 0;
        display: inline-block;
        height: $linkHeight;
        width: 10px;
        content: '';
        background: url('/img/mobilemenu-arrow-left.png') no-repeat left center;
        background-size: 9px auto;
        border-radius: 50%;
      }
    }

    &-menulink {
      &::after {
        position: absolute;
        right: 4px;
        top: 0;
        display: inline-block;
        height: $linkHeight;
        width: $linkHeight - 6px;
        content: '';
        background: url('/img/mobilemenu-arrow-right.png') no-repeat center center;
        background-size: 9px auto;
        border-radius: 50%;
      }
    }
    &-container{
      white-space: nowrap;
      overflow: hidden;
      font-size: 0;
      &--opensubmenu{
        .mobilemenu-links--mainmenu{
          margin-left: -100%;
        }
        .mobilemenu-links--submenu{
          height: auto;
        }
      }
    }
    &--mainmenu{
      @include transition(margin-left .3s);
    }
    &--submenu{
      height: 0;
      display: none;
    }
  }

  &-footer {
    $mobileMenuFooterHeight: 75px;
    @include clearfix();
    height: $mobileMenuFooterHeight;
    padding-left: 25px;
    padding-right: 25px;
    font-family: $fontRobotoCondenced;
    font-size: 12px;
    font-weight: 300;

    p {
      padding-bottom: 25px;
      height: $mobileMenuFooterHeight;
      display: flex; /* or inline-flex */
      flex-flow: row nowrap; /* is default: columns along the main-axis (row) and no wrapping to next lines */
      align-items: flex-end; /* bottom */
      margin-bottom: 0 !important;
    }

    &-address {
      float: left;
      text-align: left;
    }

    &-contact {
      float: right;
      text-align: right;
    }
  }
}
