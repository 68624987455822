@mixin grid($vertical-gap:10, $breakpoints:()) {
  @if length($breakpoints) > 0 and type-of($breakpoints) != 'map' {
    @error 'Breakpoints has to be a map.'
  }

  .grid {
    &-full-height {
      height: 100%;
    }

    @include build-grid($vertical-gap);

    @each $name, $breakpoint in $breakpoints {
      @include media($breakpoint) {
        @include build-grid($vertical-gap, '-' + $name);
      }
    }
  }
}

@mixin build-grid($vertical-gap, $prefix:'') {
  &#{$prefix}-row {
    @include row();
  }
  &#{$prefix}-outer-container {
    @include outer-container;
  }
  &#{$prefix}-fill-parent {
    @include fill-parent();
  }
  &#{$prefix}-text-left {
    text-align: left;
  }
  &#{$prefix}-text-center {
    text-align: center;
  }
  &#{$prefix}-text-right {
    text-align: right;
  }
  &#{$prefix}-hidden {
    display: none;
  }
  &#{$prefix}-visible {
    display: block;
  }
  @for $column-index from 1 through $grid-columns {
    &#{$prefix}-span#{$column-index}-nogap {
      float: left;
      display: block;
      width: percentage($column-index / $grid-columns);
      margin-right: 0;
    }
  }
  /* Does not support negative shift  */
  @for $column-index from 1 through $grid-columns {
    &#{$prefix}-shift#{$column-index}-nogap {
      margin-left: percentage($column-index / $grid-columns);
    }
  }
  @for $column-index from 1 through $grid-columns {
    &#{$prefix}-span#{$column-index} {
      @include span-columns($column-index);
    }
  }
  @for $column-index from 0 through $grid-columns - 1 {
    &#{$prefix}-shift#{$column-index} {
      @include shift($column-index);
    }
  }
  @for $column-index from 1 through $grid-columns {
    &#{$prefix}-shift-negative#{$column-index} {
      @include shift(-$column-index);
    }
  }
  &#{$prefix}-omega {
    @include omega();
  }
  @for $column-index from 1 through $grid-columns {
    &#{$prefix}-omega#{$column-index} {
      @include omega($column-index + 0n);
    }
  }
  @for $gap-index from 0 through 10 {
    &#{$prefix}-gap#{$gap-index}-below {
      margin-bottom: $vertical-gap * $gap-index + px;
    }
  }
  @for $gap-index from 0 through 10 {
    &#{$prefix}-gap#{$gap-index}-above {
      margin-top: $vertical-gap * $gap-index + px;
    }
  }
}
