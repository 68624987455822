.menu{
	list-style: none;
  padding: 0;
  margin: 0;
	white-space: nowrap;
	&-item{
		display: inline-block;
		>a, >span{
			text-decoration: none;
			color: $colorGreyDark;
      height: 48px;
      display: block;
			cursor: pointer;
			@include transition(color .2s);

      &:visited {
        color: $colorGreyDark;
      }
		}
    &:hover{
      >a, >span{
				color: lighten($colorGreyDark, 14);
      }
      .header-overlay {
				&-content{
					max-height: $overlayheight;
					@include transition(max-height .5s);
				}
      }
    }
		&:not(:first-child) {
			margin-left: 20px;
		}
		&--active{
			>a, >span{
				color: $colorGreyDark!important;
				font-weight: bold;
			}
		}
	}
}

@include media($tablet) {
  .menu:not(.menu--persistent) {
    display: none;
  }
}
