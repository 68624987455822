.login{
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-top: -6px;
  &-link{
    display: block;
    text-decoration: none;
    @include hovereffect();
  }
  &-image{
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
    height: 31px;
    border-radius: 50%;
  }
  &-icon{
    display: inline-block;
    vertical-align: middle;
  }
  &-box{
    position: absolute;
    z-index: 10000;
    top: -18px;
    right: 0;
    min-width: 218px;
    max-width: calc(100vw - 85px);
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 8px 28px 0 rgba(0, 0, 0, 0.20);
    border: 1px solid $colorGreyLight;
    max-height: 0;
    border-width: 0;
    overflow: hidden;
    opacity: 0;
    @include transition(opacity 0.3s, max-height 0s 0.3s, border-width 0s 0.3s);
    &-header{
      position: relative;
      padding: 18px;
      margin-bottom: 0px;
      @include clearfix();
      border-bottom: 1px solid $colorGreyLight;
      &-image{
        position: absolute;
        top: 17px;
        right: 18px;
        width: 31px;
        height: 31px;
        border-radius: 50%;
      }
      &-content{
        padding-right: 42px;
        line-height: 15px;
      }
    }
    &-name{
      display: block;
      font-size: 12px;
    }
    &-mail{
      font-size: 12px;
      color: $colorGrey;
      text-decoration: none;
      word-wrap: break-word;
      &:hover{
       color: darken($colorGrey, 10)
      }
    }
    &-list{
      list-style-type: none;
      margin: 0;
      padding: 0;
      text-align: left;
      li{
        position: relative;
        a{
          display: block;
          padding: 15px 22px 14px;
          text-decoration: none;
          @include transition(background-color .2s);
          i{
            vertical-align: middle;
            margin-top: -3px;
            color: $colorGrey;
            font-size: 16px;
            width: 25px;
          }
          &:hover{
            color: $colorBlueLight;
            background-color: rgba($colorOffWhite, 0.6);
            i{
              color: $colorBlueLight;
            }
          }
        }
        &:not(:last-child):after{
          position: absolute;
          content: '';
          top: 100%;
          left: 10px;
          right: 10px;
          border-bottom: 1px solid $colorGreyLight;
        }
      }
    }
  }
  &--active .login-box{
    border-width: 1px;
    max-height: 99999px;
    opacity: 1;
    @include transition(opacity 0.3s);
  }


  @include media($tablet) {
    &-box{
      box-shadow: none;
    }
    &-image{
      margin-right: 4px;
    }
    &-box{
      right: -4px;
    }
  }
}
