.text{
  &--grey{
    color: $colorGrey;
  }
  &--light{
    color: lighten($colorGrey, 18);
  }
  &--spaced{
    letter-spacing: 1.2px;
  }
}
