@import "../config";

.topic {
  &-timeline {
    .timeline-handle {
      background-color: $colorTheme !important;
    }

    .timeline-scrollarea {
      border-color: $colorTheme !important;
    }
  }
}
