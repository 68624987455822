.button{
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  padding: 4px 19px 3px 19px;
  min-width: 130px;
  border: 0;
  border-radius: 100px;
  background-color: $colorBlueLight;
  cursor: pointer;
  font-family: $fontRoboto;
  font-size: 12px;
  color: white;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 30px;
  outline: none;
  @include transition(padding .2s);
  &:after{
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    background: transparent url(/img/arrow_yellow.png) no-repeat right top;
    background-size: auto 11px;
    width: 0;
    height: 11px;
    @include transition(width .2s);
  }
  &-wrapper {
    .button{
      margin-left: 6px;
      margin-right: 6px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    @include media($mobile) {
      .button{
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  &-image{
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
  &--dark {
    background-color: $colorGreyDark;
  }
  &:hover{
    color: white;
    padding: 4px 8px 3px 8px;
    &:after{
      width: 22px;
    }
  }
  &--disabled{
    padding: 3px 18px 2px 18px!important;
    background: transparent;
    color: $colorGreyDark!important;
    box-shadow: inset 0 0 0 1px $colorGreyDark;
    cursor: default;
    pointer-events: none;
    &:after{
      content: none;
    }
  }
  &--active{
    padding: 3px 18px 2px 18px!important;
    background: transparent;
    color: $colorGreyDark!important;
    font-weight: bold;
    box-shadow: inset 0 0 0 3px $colorGreyDark;
    @include transition(background .2s);
    &:after{
      content: none;
    }
    &:hover{
      background: $colorOffWhite;
    }
  }
  &--large{
    padding: 8px 42px 7px 42px;
    &:hover{
      padding: 8px 31px 7px 31px;
    }
    &.button--disabled{
      padding: 8px 42px 7px 42px!important;
    }
    &.button--active{
      padding: 8px 42px 7px 42px!important;
    }
  }
  &--huge{
    padding: 13px 42px 12px 42px;
    &:hover{
      padding: 13px 31px 12px 31px;
    }
    &.button--disabled{
      padding: 13px 42px 12px 42px!important;
    }
  }
  &--wide{
    width: 100%;
  }
  &--fancy{
    letter-spacing: 2px;
  }
}

// To style legacy code on hubspot (might not be required in the future)
.hs-button {
  @extend .button;
  @extend .button--wide;
}
