.subtitle {
  display: block;
  margin-bottom: 8px;
  font-family: $fontRoboto;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  &--faded{
    color: lighten($colorGrey, 10);
  }
}
