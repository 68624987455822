$column: 82px;
$gutter: 14px;
$grid-columns: 12;
$max-width: 1140px;
$default-feature: max-width;

$burgerWidth:       900px; // should be 767px if using header overflow technology

// if you update these, also update the ones on components/grid10.scss
$desktop:           new-breakpoint(max-width 1180px);
$laptop:            new-breakpoint(max-width 900px);
$abovetablet:       new-breakpoint(min-width 768px);
$tablet:            new-breakpoint(max-width 767px);
$mobile:            new-breakpoint(max-width 480px);

@mixin mobile-landscape() {
  @media screen and (max-height: 700px) and (orientation: landscape) {
    @content;
  }

  @media screen and (max-width: 700px) and (orientation: portrait) {
    @content;
  }
}

$colorGreen: #56D39D;
$colorOrange: #DD7800;
$colorYellow: #FFFF99;
$colorRed: #CC0033;
$colorLightRed: #D3224E;
$colorBlue: #0F34F7;
$colorBlueLight: #56A0D3;
$colorTeal: #286c9b;
$colorBlueLightFade: #bfd5e4;

$colorOffWhite: #F5F5F5;
$colorGreyLight: #ECECEC;
$colorGrey: #939393;
$colorGreyDark: #303030;
$colorGreyDarker: #212121;

$colorTheme: $colorBlueLight;
$colorDanger: $colorRed;


$overlayheight: 350px;
$footerHeight: 596px;
$headerHeight: 80px;
$headerHeightMobile: 65px;
$headerHeightCampaign: 88px;
$mobileSideGap: 10px;

$bodyGap: 70px;
$bodyGapCampaign: 55px;
$bodyGabBurgerWidth: 35px;


// Fonts
@import url('https://fonts.googleapis.com/css?family=PT+Serif:400i|Roboto+Condensed:300,400,700|Roboto:100,300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:200,300,400,500,600,700');

$fontRoboto:             'Roboto', sans-serif, arial;
$fontRobotoCondenced:    'Roboto Condensed', sans-serif, arial;
$fontRobotoMono:         'Roboto Mono', monospace;
$fontSerif:              'PT Serif', sans-serif, arial;
