@mixin hovereffect {
  cursor: pointer;
  opacity: 1;
  @include transition(opacity .2s);
  &:hover {
    opacity: 0.8;
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin apply-to-ie11{
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content
  }
}
