.helper-normal-font-weight {
  font-weight: normal;
}
.helper-vertical-middle {
  vertical-align: middle;
}
.helper-cursor-auto {
  cursor: auto !important;
}
.helper-cursor-pointer {
  cursor: pointer !important;
}
.helper-clear {
  @include clearfix();
}
.helper-nocase{
	text-transform: none;
}
.helper-uppercase{
	text-transform: uppercase;
}
.helper-decoration-none{
	text-decoration: none;
}
.helper-relative{
	position: relative;
}
.helper-overflow-none{
	overflow: hidden;
}
.helper-center-image{
	margin-left: auto;
	margin-right: auto;
}
.helper-center-vertical{
	@include vertical-center-child(0);
}
.helper-left-image {
  float: left;
  margin: 0 8px 8px 0;
}
.helper-content-left {
  *{
    text-align: left!important;
  }
}
.helper-right-image {
  float: right;
  margin: 0 0 8px 8px;
}
.helper-padding6-below{
	padding-bottom: 60px;
}
.helper-padding10-below{
	padding-bottom: 100px;
}
.helper-mobile-padding5-below{
	@include media($mobile) {
		padding-bottom: 50px;
	}
}
.helper-mobile-gap-left{
	@include media($mobile) {
		padding-left: 10px;
	}
}
.helper-mobile-gap2-negative-above{
	margin-top: -20px;
}
.helper-mobile-fullwidth {
	@include media($mobile) {
		max-width: 100% !important;
		margin-left: 0;
		margin-right: 0;
	}
}

.helper-fullwidth {
	width: 100%;
}

.helper-block {
	display: block;
}
.helper-inlineblock {
	display: inline-block;
}

.helper-disabledlink {
	pointer-events: none;
	cursor: default;
}

.helper-nogap{
	margin: 0!important;
}

.helper-nowrap{
	white-space: nowrap;
}

.helper-fade-bg{
	background-color: $colorGreyLight;
}

.helper-trim{
	> *:last-child{
		margin-bottom: 0!important;
	}
}
.helper-nobreak{
  white-space: nowrap;
}
.helper-nooutline{
  outline: none;
}
.helper-child-no-bottom-margin > * {
  @extend .grid-gap0-below;
}

h1.helper-ignore-headline {
  text-transform: none;
  font-family: $fontRoboto;
}

.helper-mobile-inlineblock {
  @include media($mobile) {
    display: inline-block !important;
  }
}